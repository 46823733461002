import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'tailwindcss/tailwind.css';

const PinotSchemaGenerator = () => {
  const [jsonInput, setJsonInput] = useState(`{
    "session_id": "abc123",
    "user_id": "user_456",
    "event": {
        "type": "product_view",
        "details": {
            "product_id": "prod_789",
            "category": "electronics",
            "price": 299.99
        }
    },
    "page": {
        "url": "https://example.com/product/prod_789",
        "referrer": "https://example.com/home"
    },
    "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36",
    "ip_address": "192.168.1.1",
    "geolocation": {
        "country": "United States",
        "city": "San Francisco"
    },
    "timestamp": "2024-08-13T19:12:11.937Z"
  }`);
  const [generatedSchema, setGeneratedSchema] = useState(null);
  const [includeTransformFields, setIncludeTransformFields] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const textAreaRef = useRef(null);

  const handleJsonChange = (e) => {
    setJsonInput(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setIncludeTransformFields(e.target.checked);
  };

  const handleGenerateSchema = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post('https://pinot-schema-generator-api-36lkmurbjq-uc.a.run.app/generate-schema', JSON.parse(jsonInput), {
        params: {
          addTransformFields: includeTransformFields,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setGeneratedSchema(response.data);
    } catch (err) {
      setError('Invalid JSON or server error');
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setJsonInput('');
    setGeneratedSchema(null);
    setError(null);
  };

  const handleCopyToClipboard = () => {
    if (generatedSchema) {
      navigator.clipboard.writeText(JSON.stringify(generatedSchema, null, 2));
    }
  };

  // Adjust the height of the textarea dynamically based on content
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [generatedSchema]);

  return (
    <div className="container mx-auto p-6">
      <div className="flex flex-col md:flex-row gap-6">
        <div className="w-full md:w-1/2">
          <h2 className="text-lg font-semibold mb-2">Apache Pinot Schema Generator</h2>
          <p className="mb-4 text-sm text-gray-700">
            This tool is designed to simplify the process of creating Apache Pinot schemas from JSON data. By pasting your JSON payload into the tool, you can automatically generate a schema that Apache Pinot can use to ingest and query your data. The tool identifies the appropriate data types, such as STRING or INT, and can handle complex, nested JSON structures.
          </p>
          <textarea
            value={jsonInput}
            onChange={handleJsonChange}
            placeholder="Paste your JSON payload here..."
            className="w-full h-64 p-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 font-mono"
          ></textarea>
          {error && <p className="text-red-500 mt-2">{error}</p>}
          
          <div className="mt-4 flex justify-between items-center">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={includeTransformFields}
                onChange={handleCheckboxChange}
                className="form-checkbox text-blue-600"
              />
              <span className="ml-2">Add Fields for Optional Ingestion Transformations</span>
            </label>
            <div className="flex gap-4">
              <button
                onClick={handleGenerateSchema}
                disabled={!jsonInput || loading}
                className="bg-blue-600 text-white py-2 px-4 rounded disabled:bg-blue-300"
              >
                {loading ? 'Generating...' : 'Generate Schema'}
              </button>
              <button
                onClick={handleClear}
                className="bg-gray-500 text-white py-2 px-4 rounded"
              >
                Clear
              </button>
            </div>
          </div>

          <div className="mt-6">
            <p className="text-sm text-gray-700 mb-4">
              <strong>Supported Features:</strong>
              <ul className="list-disc pl-5">
                <li>Automatic Schema Generation: Generates an Apache Pinot schema from a given JSON payload, automatically detecting data types such as STRING, INT, DOUBLE, BOOLEAN, TIMESTAMP, BYTES, and JSON.</li>
                <li>Nested JSON Handling: Supports nested JSON objects, recursively generating schema fields that reflect the structure of the original JSON.</li>
                <li>Transformation Fields: Optionally generates additional fields for ingestion time transformations, allowing you to extract and store specific fields from nested JSON objects as separate columns.</li>
              </ul>
            </p>
            <p className="text-sm text-gray-700 mb-4">
              <strong>Limitations:</strong>
              <ul className="list-disc pl-5">
                <li>Only JSON Input: The generator currently only supports JSON payloads as input. Other data formats are not supported.</li>
                <li>Limited Data Type Detection: While common data types like STRING, INT, DOUBLE, and BOOLEAN are supported, complex types like arrays or custom objects require JSON representation.</li>
              </ul>
            </p>
            <p className="text-sm text-gray-700">
              <strong>Note:</strong> Schema design and data modeling can get tricky and really depend on your specific use case. This tool is a great way to kickstart your Apache Pinot schema, but it might not cover all the bases. For the finer details, you might want to check out the Apache Pinot documentation or talk to an expert.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <h2 className="text-lg font-semibold mb-2">Generated Pinot Schema</h2>
          <div className="relative">
            <textarea
              ref={textAreaRef}
              value={generatedSchema ? JSON.stringify(generatedSchema, null, 2) : "Click 'Generate Schema' to give it a try"}
              readOnly
              className="w-full p-4 border rounded-md font-mono bg-gray-100 focus:outline-none resize-none"
              style={{ minHeight: "150px" }} // Ensure a minimum height on load
            ></textarea>
            <button
              onClick={handleCopyToClipboard}
              className="absolute top-2 right-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-1 px-2 rounded"
              disabled={!generatedSchema} // Disable copy button if there's no generated schema
            >
              📋 Copy
            </button>
          </div>
        </div>
      </div>
      <div className="mt-6 text-right">
        <p className="text-sm text-gray-700">
          If you accidentally landed on this page and don't know what Apache Pinot is, visit <a href="https://pinot.apache.org/" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
      </div>
    </div>
  );
};

export default PinotSchemaGenerator;
