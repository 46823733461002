import React from 'react';
import PinotSchemaGenerator from './components/PinotSchemaGenerator';
import './index.css';

function App() {
  return (
    <div className="App">
      <header className="bg-blue-600 text-white p-4 text-center">
        <h1 className="text-2xl font-bold">Pinot Schema Generator</h1>
      </header>
      <main className="p-6">
        <PinotSchemaGenerator />
      </main>
    </div>
  );
}

export default App;
